import { useState } from "react";
import { FaBell } from "react-icons/fa";

const NotificationButton = ({ notificationCount }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      {/* Notification Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 p-2.5 rounded-full hover:bg-gray-200 bg-gray-200 dark:bg-gray-700 transition-all"
      >
        <FaBell />
        {notificationCount > 0 && (
          <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white text-xs font-bold px-1.5 py-0.5 rounded-full">
            {notificationCount}
          </span>
        )}
      </button>

      {/* Dropdown Box */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
          <h3 className="text-sm font-semibold">Notifications</h3>
          <p className="text-xs text-gray-500">No new notifications</p>
        </div>
      )}
    </div>
  );
};

// Default props to make the badge optional
NotificationButton.defaultProps = {
  notificationCount: 0,
};

export default NotificationButton;
