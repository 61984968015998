import { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import CreditBalanceBadge from "./CreditBalanceBadge";
import {
  FaSun,
  FaMoon,
  FaDesktop,
  FaRedoAlt,
  FaBell,
  FaRedo,
} from "react-icons/fa";
import TimezoneInfo from "./TimezoneInfo";
import NotificationButton from "./NotificationButton";

const Header = ({ toggleSidebar }) => {
  const {
    isAuthenticated,
    user,
    logout,
    isAdmin,
    isInstallable,
    handleInstall,
  } = useContext(AuthContext);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [accountSettingsPath, setAccountSettingsPath] = useState("");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    setAccountSettingsPath(
      user.role === "admin" ? "/admin/account-settings" : "/account-settings"
    );

    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    logout(navigate);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleHardRefresh = () => {
    window.location.href =
      window.location.href.split("?")[0] + "?t=" + new Date().getTime();
  };

  return (
    <header className="py-4 px-6 dark:bg-gray-900 dark:text-white dark:border-b-4 dark:border-white-100 flex justify-between items-center border-b-4 border-indigo-600 dark:border-gray-500">
      <button
        onClick={toggleSidebar}
        className="dark:text-white text-lg block md:hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="size-7"
        >
          <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
      {/* Add to Desktop Shortcut Button */}
      {isInstallable && (
        <button
          onClick={handleInstall}
          className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition-all"
        >
          <div className="flex items-center space-x-4 ml-auto">
            <FaDesktop />
            <p className="hidden sm:block mx-0">Install Desktop App</p>
          </div>
        </button>
      )}

      <div className="flex items-center space-x-4 ml-auto">
        <TimezoneInfo adminTimezone="Europe/Skopje" />
        {!isAdmin && <CreditBalanceBadge balance={user?.credit_balance || 0} />}

        <NotificationButton />

        {/* Hard Refresh Button */}
        <button
          onClick={handleHardRefresh}
          className="flex items-center space-x-2 p-2.5 rounded-full hover:bg-gray-200 bg-gray-200 dark:bg-gray-700 transition-all"
        >
          <FaRedo />
        </button>

        <div className="flex items-center space-x-2">
          <button
            onClick={toggleDarkMode}
            className="focus:outline-none p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors"
          >
            {isDarkMode ? (
              <FaSun className="text-yellow-400 w-5 h-5" />
            ) : (
              <FaMoon className="text-gray-800 dark:text-gray-200 w-5 h-5" />
            )}
          </button>
        </div>

        {/* User Dropdown */}
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="focus:outline-none flex items-center space-x-2"
          >
            <div className="w-10 h-10 rounded-full overflow-hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                className="w-full h-full"
              >
                <circle cx="50" cy="50" r="50" fill="#2D3748" />
                <circle cx="50" cy="35" r="18" fill="#A0AEC0" />
                <path
                  d="M50,58c-16.57,0-30,8.43-30,18v4h60v-4C80,66.43,66.57,58,50,58z"
                  fill="#718096"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="48"
                  stroke="#E2E8F0"
                  strokeWidth="4"
                  fill="none"
                />
              </svg>
            </div>

            <span className="text-sm font-medium">
              <strong>
                {isAuthenticated ? user && user.username : "Guest"}
              </strong>
            </span>
            <svg
              className={`w-4 h-4 transform transition-transform ${
                isDropdownOpen ? "rotate-180" : "rotate-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 text-gray-900 dark:text-white shadow-lg rounded-lg z-10">
              {isAuthenticated && (
                <>
                  <Link
                    to={accountSettingsPath}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    Account Settings
                  </Link>

                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    Logout
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
