import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import { decodeJWT } from "../utils/JwtDecode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [background, setBackground] = useState(null);
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);

  // Dark mode state
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // Effect to toggle dark mode class
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  // Auth logic
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    if (storedToken && storedUser) {
      try {
        setToken(storedToken);
        setUser(JSON.parse(storedUser));
        setIsAuthenticated(true);
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchBackgroundImage();
  }, []);

  const fetchBackgroundImage = async () => {
    try {
      const response = await axiosInstance.get("/login/background");
      setBackground(response.data);
      setIsBackgroundLoaded(true);
    } catch (e) {
      setIsBackgroundLoaded(false);
    }
  };

  const login = (token, navigate) => {
    const decodedToken = decodeJWT(token);

    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("user", decodedToken.sub);
    setUser(JSON.parse(decodedToken.sub));
    setIsAuthenticated(true);

    const currentUser = JSON.parse(decodedToken.sub);
    if (currentUser.role === "admin") {
      setIsAdmin(true);
      navigate("/admin");
    } else if (currentUser.role === "customer") {
      setIsAdmin(false);
      navigate("/");
    }
  };

  const register = (newUser, navigate) => {
    const { email, username, phone, country, password } = newUser;

    if (email && password && username && phone && country) {
      setUser({ email, username, phone, country });
      setIsAuthenticated(true);
      navigate("/");
    } else {
      alert("Registration failed. Please fill in all the required fields.");
    }
  };

  const logout = (navigate) => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  const updateUser = (updatedUser) => {
    setUser(updatedUser);
  };

  const updateUserLocal = () => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  const resetUser = () => {
    const storedUser = localStorage.getItem("user");
    setUser(JSON.parse(storedUser));
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsInstallable(false);
    } else {
      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    }

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstall = async () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user's response
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      // Reset the deferred prompt after use
      setDeferredPrompt(null);
      setIsInstallable(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        token,
        loading,
        login,
        register,
        logout,
        isDarkMode,
        setIsDarkMode,
        isAdmin,
        updateUser,
        updateUserLocal,
        resetUser,
        isInstallable,
        handleInstall,
        background,
        isBackgroundLoaded,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
