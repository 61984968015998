import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import ErrorAlert from "../components/ErrorAlert";
import logo from "../assets/logo.png";
import Loader from "../components/Loader";
import { PlayIcon } from "@heroicons/react/24/solid";

const Login = () => {
  const { login, background, isBackgroundLoaded } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = { email, password };
    setIsLoading(true);

    try {
      const response = await axiosInstance.post("/login", loginData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response;

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Login failed!");
      }

      login(data.token, navigate);
    } catch (error) {
      setError(error.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = (e) => {
    // Close the modal if clicked outside the iframe container
    if (e.target.id === "modal-overlay") {
      setIsModalOpen(false);
    }
  };

  return (
    <div className="min-h-screen flex bg-white dark:bg-gray-900 relative">
      <div className="relative w-2/3 bg-gray-700">
        {!isBackgroundLoaded ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
            <Loader />
          </div>
        ) : background.type === "IMAGE" ? (
          <div
            className={`w-full h-full bg-cover bg-center transition-opacity duration-500 ${
              isBackgroundLoaded ? "opacity-100" : "opacity-0"
            }`}
            style={{
              backgroundImage: `url("${background?.url}")`,
            }}
          ></div>
        ) : (
          <video
            className={`w-full h-full bg-cover bg-center transition-opacity duration-500 ${
              isBackgroundLoaded ? "opacity-100" : "opacity-0"
            }`}
            width="600"
            autoPlay
            muted
            loop
            preload="metadata"
          >
            <source src={background?.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {/* Play Button */}
      <button
        className="absolute top-4 right-4 bg-red-600 text-white rounded-full p-3 shadow-lg hover:bg-red-700 flex items-center justify-center space-x-1"
        onClick={() => setIsModalOpen(true)}
      >
        <PlayIcon className="w-6 h-6" />
        <span className="text-sm font-bold">Watch</span>
      </button>

      <div className="w-2/3 flex items-center justify-center">
        <div className="w-full max-w-md px-8 py-10 bg-white shadow-lg rounded-lg dark:bg-gray-800">
          <div className="flex flex-col items-center mb-4">
            <img src={logo} alt="Logo" className="w-12 h-auto mb-4" />
            <h1 className="text-3xl font-bold text-red-600">VDP Tuning Team</h1>
          </div>
          <h2 className="text-2xl font-bold text-center text-gray-800 dark:text-white mb-4">
            Welcome
          </h2>

          {error && <ErrorAlert errorMessage={error} />}
          <br />

          <form
            onSubmit={handleSubmit}
            className="space-y-4"
            action="/login"
            method="post"
            autoComplete="on"
          >
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                name="email"
                id="email"
                autoComplete="username"
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                name="password"
                id="password"
                autoComplete="current-password"
                required
              />
            </div>
            <div className="text-right">
              <Link
                to="/forgot-password"
                className="text-sm text-red-600 hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
            <div>
              <button
                className={`w-full py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700 ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "LOGIN"}
              </button>
            </div>
          </form>
          <div className="mt-6 text-center">
            <Link to="/register">
              <button className="w-full py-2 bg-gray-200 text-gray-600 font-bold rounded-md hover:bg-gray-300">
                CREATE NEW ACCOUNT
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          id="modal-overlay"
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeModal}
        >
          <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden max-w-3xl w-full shadow-lg">
            <div className="relative pb-[56.25%] h-0">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/hwacIuP2NDA?si=hpGCVmiGdGO95YoI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
